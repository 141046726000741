import React from 'react'
import { graphql } from 'gatsby'
import { Text, Link } from '../components/ui'
import { EventList } from '../components/events'
import { MetaTagsMustHave } from '../components/seo'
import { SidebarEvents } from '../components/sidebar'
import { PageLayout, LayoutSection } from '../components/layout'
import { ForwardLink } from '../components/content'
import Ads from '../components/content/Ads'

const TodaysEvents = ({ data }) => {
  const { page } = data.wp.themeOptions
  const { nodes: todaysEvents } = data.wp.todaysEvents
  const { nodes: futureEvents } = data.wp.futureEvents

  return (
    <>
      <MetaTagsMustHave {...page.seo} />
      <Ads placement="magnumSubPage" />
      <PageLayout sideNode={<SidebarEvents />}>
        <LayoutSection
          headingAs="h1"
          heading={page.title}
        >
          {
            (todaysEvents && todaysEvents.length)
              ? <EventList events={todaysEvents} />
              : (
                <Text my={2}>
                  Nincsenek mai programok.
                </Text>
              )
          }
        </LayoutSection>
        <LayoutSection
          as="section"
          heading="Programajánló"
        >
          {
            futureEvents?.length > 0
              ? (
                <EventList
                  titleAs="h3"
                  events={futureEvents.slice(0, 10)}
                />
              )
              : (
                <Text my={2}>
                  Nincsenek programok.
                </Text>
              )
          }

          {
            futureEvents.length > 10 && (
              <ForwardLink
                as={Link}
                to="/programok"
              >
                Még több program
              </ForwardLink>
            )
          }
        </LayoutSection>
      </PageLayout>
    </>
  )
}

export default TodaysEvents

export const pageQuery = graphql`
  query TodaysEventsQuery {
    wp {
      themeOptions {
        page: todaysEventsPage {
          title
          seo {
            ...SeoFields
          }
        }
      }
      todaysEvents: events(
        first: 11
        where: {
          scope: TODAY
          language: DEFAULT
          status: PUBLISH
          orderby: {
            order: ASC
            field: START_DATE
          }
        }
      ) {
        nodes {
          ...EventListFields
          ...EventListImageFields
        }
      }
      futureEvents: events(
        first: 5
        where: {
          scope: FUTURE
          language: DEFAULT
          status: PUBLISH
          orderby: {
            order: ASC
            field: START_DATE
          }
        }
      ) {
        nodes {
          ...EventListFields
          ...EventListImageFields
        }
      }
    }
  }
`
